import React from 'react';
import { Navigate } from 'react-router-dom';

import MealTables from '@pages/reports/mealTables';
import RoomAllocationTables from '@pages/reports/roomAllocationTables';
import DailyReserveRegistCardsPrint from '@pages/reservation/accommodation/dailyReserveRegistCardsPrint';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const ResetPassword = React.lazy(() => import('../pages/auth/ResetPassword'));
const Dashboard = React.lazy(() => import('../pages/dashboard'));

// reservation management
const AccommRsvEditor = React.lazy(() => import('../pages/reservation/accommodation/editor'));
const BanquetRsvEditor = React.lazy(() => import('../pages/reservation/banquet/editor'));
const AccommRsvManagement = React.lazy(() => import('../pages/reservation/accommodation'));
const BanquetRsvManagement = React.lazy(() => import('../pages/reservation/banquet'));
const AccommodationPlanRegister = React.lazy(
  () => import('../pages/settings/plans/accommodation/accommodationPlanRegister')
);
const AccommInDayTripRsvManagement = React.lazy(() => import('../pages/reservation/inDayTrip'));
const AccommInDayTripRsvEditor = React.lazy(() => import('../pages/reservation/inDayTrip/editor'));
const ReserveRegistCard = React.lazy(() => import('../pages/reservation/accommodation/editor/registCard'));
const RegistCardSuccess = React.lazy(() => import('../pages/reservation/accommodation/editor/registCardSuccess'));
const ReserveRegistCardPrint = React.lazy(() => import('../pages/reservation/accommodation/editor/registCardPrint'));
const ReserveRegistCardConfirm = React.lazy(
  () => import('../pages/reservation/accommodation/editor/registCardConfirm')
);
// guest management
const GuestManagement = React.lazy(() => import('../pages/guests'));
const GuestEditor = React.lazy(() => import('../pages/guests/editor'));

// room management
const RoomManagement = React.lazy(() => import('../pages/rooms'));
const RegistCardPrint = React.lazy(() => import('../pages/reservation/accommodation/registCardPrint'));
const PrintBill = React.lazy(() => import('../pages/personalBill/printBillAccomm'));
const PrintBillInDayTrip = React.lazy(() => import('../pages/personalBill/printBillInDayTrip'));
const PrinBillBanquet = React.lazy(() => import('../pages/personalBill/printBillBanquet'));

// settings
const AccommItemsSetting = React.lazy(() => import('../pages/settings/salesItems/accommodation'));
const AccommPlanSetting = React.lazy(() => import('../pages/settings/plans/accommodation'));
const AccountSetting = React.lazy(() => import('../pages/settings/account'));
const AgencySetting = React.lazy(() => import('../pages/settings/agencies'));
const BanquetItemsSetting = React.lazy(() => import('../pages/settings/salesItems/banquet'));
const BuildingSetting = React.lazy(() => import('../pages/settings/buildings'));
const DepositSetting = React.lazy(() => import('../pages/settings/depositItems'));
const PropertySetting = React.lazy(() => import('../pages/settings/properties'));
const RoomSetting = React.lazy(() => import('../pages/settings/rooms'));
const RoomtypeSetting = React.lazy(() => import('../pages/settings/roomtypes'));
const StaffSetting = React.lazy(() => import('../pages/settings/staffs'));
const TaxSetting = React.lazy(() => import('../pages/settings/taxes'));
const BanquetPlanSetting = React.lazy(() => import('../pages/settings/plans/banquet'));
const MealVenuesSetting = React.lazy(() => import('../pages/settings/mealVenues'));
const MealPlanRegister = React.lazy(() => import('../pages/settings/mealPlans/mealPlanRegister'));
const MealPlanList = React.lazy(() => import('../pages/settings/mealPlans/index'));
const AccommodationPanelList = React.lazy(() => import('../pages/settings/accommodationPanels/index'));
const BanquetVenuesSetting = React.lazy(() => import('../pages/settings/banquetVenues/index'));

// reports
const SaleDailyReport = React.lazy(() => import('../pages/reports/saleDailyReport'));
const SaleMonthlyReport = React.lazy(() => import('../pages/reports/saleMonthlyReport'));
const AccommodationTaxesReport = React.lazy(() => import('../pages/reports/accommodationTaxes'));
const ReceivableAccount = React.lazy(() => import('../pages/reports/receivableAccount'));
const SaleByItems = React.lazy(() => import('../pages/reports/saleByItems'));
const SaleAnnually = React.lazy(() => import('../pages/reports/saleAnnually'));
const SaleAgency = React.lazy(() => import('../pages/reports/saleAgency'));
const CancelReport = React.lazy(() => import('../pages/reports/cancelReport'));
const CleaningCheckList = React.lazy(() => import('../pages/reports/cleaningCheckList'));
const PickUpList = React.lazy(() => import('../pages/reports/pickUpList'));
const CallList = React.lazy(() => import('../pages/reports/callList'));
// rank management
const RankManagement = React.lazy(() => import('../pages/rankTypes/index'));
const RankEditor = React.lazy(() => import('../pages/rankTypes/editor'));

// master
const MasterList = React.lazy(() => import('../pages/master/index'));

// room management
const PlanPrice = React.lazy(() => import('../pages/planPrice'));

const SplitBill = React.lazy(() => import('../pages/splitBill/splitBillAccomm'));
const SplitBillInDayTrip = React.lazy(() => import('../pages/splitBill/splitBillInDayTrip'));
const SplitBillBanquetPlan = React.lazy(() => import('../pages/splitBill/splitBillBanquet'));

// schedule day off
const ScheduleDayOff = React.lazy(() => import('../pages/settings/scheduleDayOff'));

// in day trip
const InDayTripPlan = React.lazy(() => import('../pages/settings/inDayTripPlan/index'));
const InDayTripPlanEditor = React.lazy(() => import('../pages/settings/inDayTripPlan/editor'));

// location
const Location = React.lazy(() => import('../pages/location/index'));
const CreateLocation = React.lazy(() => import('../pages/location/create/index'));

//guest types
const GuestTypes = React.lazy(() => import('../pages/settings/guestTypes/index'));
const GuestTypesEditor = React.lazy(() => import('../pages/settings/guestTypes/editor'));
// traffic-methods
const TrafficMethod = React.lazy(() => import('../pages/trafficMethods/index'));
const TrafficMethodEditor = React.lazy(() => import('../pages/trafficMethods/editor/index'));

// banquet-plans
const BanquetPlanEditor = React.lazy(() => import('../pages/settings/plans/banquet/editor'));

interface AppRoute {
  children?: AppRoute[];
  component: React.ComponentType;
  path: string;
  menuId?: string;
}

// root routes
const rootRoute: AppRoute = {
  path: '/',
  component: () => <Navigate to="/dashboard" />,
};

// flatten the list of all nested routes
const flattenRoutes = (routes: AppRoute[]) => {
  let flatRoutes: AppRoute[] = [];

  routes.forEach((item) => {
    flatRoutes.push(item);

    if (item.children) {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

const dashboardRoutes: AppRoute = {
  path: '/dashboard',
  component: Dashboard,
  menuId: 'P1',
};

const reservationRoutes: AppRoute[] = [
  {
    path: '/reservations/accommodation',
    component: AccommRsvManagement,
    menuId: 'P2',
  },
  {
    path: '/reservations/accommodation/new',
    component: AccommRsvEditor,
    menuId: 'P2',
  },
  {
    path: '/reservations/accommodation/:rsvId',
    component: AccommRsvEditor,
    menuId: 'P2',
  },
  {
    path: '/reservations/in-day-trip',
    component: AccommInDayTripRsvManagement,
    menuId: 'P38',
  },
  {
    path: '/reservations/in-day-trip/new',
    component: AccommInDayTripRsvEditor,
    menuId: 'P38',
  },
  {
    path: '/reservations/in-day-trip/:rsvId',
    component: AccommInDayTripRsvEditor,
    menuId: 'P38',
  },
  {
    path: '/reservations/in-day-trip/print-bill',
    component: PrintBillInDayTrip,
    menuId: 'P38',
  },
  {
    path: '/reservations/in-day-trip/:rsvId/split-bill',
    component: SplitBillInDayTrip,
    menuId: 'P38',
  },
  {
    path: '/reservations/accommodation/:rsvId/split-bill',
    component: SplitBill,
    menuId: 'P2',
  },
  {
    path: '/reservations/accommodation/:rsvId/regist-card/rooms/:listIdx',
    component: ReserveRegistCard,
    menuId: 'P2',
  },
  {
    path: '/reservations/accommodation/:rsvId/regist-card/rooms/:listIdx/print',
    component: ReserveRegistCardPrint,
    menuId: 'P2',
  },
  {
    path: '/reservations/accommodation/regist-card-print',
    component: RegistCardPrint,
    menuId: 'P2',
  },
  {
    path: '/reservations/accommodation/print-bill',
    component: PrintBill,
    menuId: 'P2',
  },
  {
    path: '/reservations/accommodation/:rsvId/regist-card/rooms/:listIdx/confirm',
    component: ReserveRegistCardConfirm,
    menuId: 'P2',
  },
  {
    path: '/reservations/accommodation/regist-cards/print',
    component: DailyReserveRegistCardsPrint,
    menuId: 'P2',
  },
  {
    path: '/reservations/banquet',
    component: BanquetRsvManagement,
    menuId: 'P3',
  },
  {
    path: '/reservations/banquet/new',
    component: BanquetRsvEditor,
    menuId: 'P3',
  },
  {
    path: '/reservations/banquet/:rsvId',
    component: BanquetRsvEditor,
    menuId: 'P3',
  },
  {
    path: '/reservations/accommodation/regist-card-success',
    component: RegistCardSuccess,
    menuId: 'P2',
  },
  {
    path: '/reservations/banquet/print-bill/:id',
    component: PrinBillBanquet,
  },
  {
    path: '/reservations/banquet/:id/split-bill',
    component: SplitBillBanquetPlan,
    menuId: 'P38',
  },
];

const guestRoutes: AppRoute[] = [
  {
    path: '/guests',
    component: GuestManagement,
    menuId: 'P4',
  },
  {
    path: '/guests/new',
    component: GuestEditor,
    menuId: 'P4',
  },
  {
    path: '/guests/:guestId',
    component: GuestEditor,
    menuId: 'P4',
  },
];

const roomsRoutes: AppRoute = {
  path: '/rooms',
  component: RoomManagement,
  menuId: 'P5',
};

const settingRoutes: AppRoute[] = [
  {
    path: '/settings/account',
    component: AccountSetting,
    menuId: 'P25',
  },
  {
    path: '/settings/staffs',
    component: StaffSetting,
    menuId: 'P26',
  },
  {
    path: '/settings/properties',
    component: PropertySetting,
    menuId: 'P27',
  },
  {
    path: '/settings/tax-types',
    component: TaxSetting,
    menuId: 'P22',
  },
  {
    path: '/settings/room-types',
    component: RoomtypeSetting,
    menuId: 'P18',
  },
  {
    path: '/settings/rooms',
    component: RoomSetting,
    menuId: 'P17',
  },
  {
    path: '/settings/deposit-items',
    component: DepositSetting,
    menuId: 'P21',
  },
  {
    path: '/settings/accommodation-items',
    component: AccommItemsSetting,
    menuId: 'P19',
  },
  {
    path: '/settings/banquet-items',
    component: BanquetItemsSetting,
    menuId: 'P20',
  },
  {
    path: '/settings/buildings',
    component: BuildingSetting,
    menuId: 'P23',
  },
  {
    path: '/settings/accommodation-plans',
    component: AccommPlanSetting,
    menuId: 'P15',
  },
  {
    path: '/settings/accommodation-plans/new',
    component: AccommodationPlanRegister,
    menuId: 'P15',
  },
  {
    path: '/settings/accommodation-plans/:id/edit',
    component: AccommodationPlanRegister,
    menuId: 'P15',
  },
  {
    path: '/settings/accommodation-plans/pricing',
    component: PlanPrice,
    menuId: 'P31',
  },
  {
    path: '/settings/banquet-plans',
    component: BanquetPlanSetting,
    menuId: 'P16',
  },
  {
    path: '/settings/banquet-plans/new',
    component: BanquetPlanEditor,
  },
  {
    path: '/settings/banquet-plans/:id/edit',
    component: BanquetPlanEditor,
  },
  {
    path: '/settings/agencies',
    component: AgencySetting,
    menuId: 'P24',
  },
  {
    path: '/settings/meal-venues',
    component: MealVenuesSetting,
    menuId: 'P28',
  },
  {
    path: '/settings/meal-plans',
    component: MealPlanList,
    menuId: 'P29',
  },
  {
    path: '/settings/meal-plans/new',
    component: MealPlanRegister,
    menuId: 'P29',
  },
  {
    path: '/settings/meal-plans/:id/edit',
    component: MealPlanRegister,
    menuId: 'P29',
  },
  {
    path: '/settings/building-panels',
    component: AccommodationPanelList,
    menuId: 'P30',
  },
  {
    path: '/settings/schedule-day-off',
    component: ScheduleDayOff,
    menuId: 'P35',
  },
  {
    path: '/settings/in-day-trip-plans',
    component: InDayTripPlan,
    menuId: 'P39',
  },
  {
    path: '/settings/in-day-trip-plans/new',
    component: InDayTripPlanEditor,
    menuId: 'P39',
  },
  {
    path: '/settings/in-day-trip-plans/:id/edit',
    component: InDayTripPlanEditor,
    menuId: 'P39',
  },
  {
    path: '/settings/guest-types',
    component: GuestTypes,
    menuId: 'P41',
  },
  {
    path: '/settings/guest-types/new',
    component: GuestTypesEditor,
    menuId: 'P41',
  },
  {
    path: '/settings/guest-types/:id/edit',
    component: GuestTypesEditor,
    menuId: 'P41',
  },
  {
    path: 'settings/traffic-methods/edit',
    component: TrafficMethodEditor,
    menuId: 'P42',
  },
  {
    path: 'settings/traffic-methods/:id',
    component: TrafficMethodEditor,
    menuId: 'P42',
  },
  {
    path: 'settings/traffic-methods',
    component: TrafficMethod,
    menuId: 'P42',
  },
  {
    path: 'settings/locations/create',
    component: CreateLocation,
    menuId: 'P40',
  },
  {
    path: 'settings/locations/:id',
    component: CreateLocation,
    menuId: 'P40',
  },
  {
    path: 'settings/locations',
    component: Location,
    menuId: 'P40',
  },
  {
    path: 'settings/banquet-venues',
    component: BanquetVenuesSetting,
  },
];

const reportRoutes: AppRoute[] = [
  {
    path: '/reports/sales-daily',
    component: SaleDailyReport,
    menuId: 'P6',
  },
  {
    path: '/reports/sales-monthly',
    component: SaleMonthlyReport,
    menuId: 'P7',
  },
  {
    path: 'reports/receivable-accounts',
    component: ReceivableAccount,
    menuId: 'P10',
  },
  {
    path: '/reports/sales-annually',
    component: SaleAnnually,
    menuId: 'P8',
  },
  {
    path: '/reports/accommodation-taxes',
    component: AccommodationTaxesReport,
    menuId: 'P9',
  },
  {
    path: '/reports/sales-agency',
    component: SaleAgency,
    menuId: 'P11',
  },
  {
    path: '/reports/sales-by-items',
    component: SaleByItems,
    menuId: 'P12',
  },
  {
    path: '/reports/cancel-report',
    component: CancelReport,
    menuId: 'P13',
  },
  {
    path: '/reports/meal-tables',
    component: MealTables,
    menuId: 'P33',
  },
  {
    path: '/reports/room-allocation-tables',
    component: RoomAllocationTables,
    menuId: 'P34',
  },
  {
    path: '/reports/cleaning-checklist',
    component: CleaningCheckList,
    menuId: 'P36',
  },
  {
    path: '/reports/pickup-list',
    component: PickUpList,
    menuId: 'P37',
  },
  {
    path: '/reports/call-list',
    component: CallList,
    menuId: 'P37',
  },
];

const rankRoutes: AppRoute[] = [
  {
    path: '/rank-types',
    component: RankManagement,
    menuId: 'P32',
  },
  {
    path: '/rank-types/new',
    component: RankEditor,
    menuId: 'P32',
  },
  {
    path: '/rank-types/:rankTypeId',
    component: RankEditor,
    menuId: 'P32',
  },
];

const masterRoute: AppRoute = {
  path: '/master-list',
  component: MasterList,
};

// auth
const authRoutes: AppRoute[] = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/logout',
    component: Logout,
  },
  {
    path: '/forget-password',
    component: ForgetPassword,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
  },
];

// All routes
const authProtectedRoutes = [
  ...guestRoutes,
  ...reportRoutes,
  ...reservationRoutes,
  ...settingRoutes,
  dashboardRoutes,
  roomsRoutes,
  rootRoute,
  ...rankRoutes,
  masterRoute,
];

const publicRoutes = [...authRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicFlattenRoutes = flattenRoutes([...publicRoutes]);

export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicFlattenRoutes };
